@tailwind base;
@tailwind components;
@tailwind utilities;
@layer utilities {
	.base-layout-margin {
		@apply pl-sm-margin pr-sm-margin lg:pr-lg-margin lg:pl-lg-margin;
	}

	.text-xs,
	.text-sm,
	.text-base,
	.text-lg,
	.text-xl,
	.text-2xl,
	.text-3xl,
	.text-4xl,
	.text-5xl,
	.text-6xl,
	.text-7xl,
	.text-8xl,
	.text-9xl {
		@apply tracking-[.27px] leading-none;
	}
}

@import url('https://p.typekit.net/p.css?s=1&k=ywg7oiq&ht=tk&f=24349.24350.24351.24352.24353.24354.24355.24356.37896.37897.37898.37899.37900&a=4046592&app=typekit&e=css');

@font-face {
	font-family: 'Gibson';
	src: url('https://use.typekit.net/af/299bdc/00000000000000003b9b0ba5/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
			format('woff2'),
		url('https://use.typekit.net/af/299bdc/00000000000000003b9b0ba5/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
			format('woff'),
		url('https://use.typekit.net/af/299bdc/00000000000000003b9b0ba5/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
			format('opentype');
	font-display: auto;
	font-style: normal;
	font-weight: 400;
	font-stretch: normal;
}

@font-face {
	font-family: 'Gibson Bold';
	src: url('https://use.typekit.net/af/1d6761/00000000000000003b9b0ba9/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
			format('woff2'),
		url('https://use.typekit.net/af/1d6761/00000000000000003b9b0ba9/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
			format('woff'),
		url('https://use.typekit.net/af/1d6761/00000000000000003b9b0ba9/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
			format('opentype');
	font-display: auto;
	font-style: normal;
	font-weight: 700;
	font-stretch: normal;
}

@font-face {
	font-family: 'Bebas Neue';
	src: url('https://use.typekit.net/af/630fe9/00000000000000003b9b1558/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
			format('woff2'),
		url('https://use.typekit.net/af/630fe9/00000000000000003b9b1558/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
			format('woff'),
		url('https://use.typekit.net/af/630fe9/00000000000000003b9b1558/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
			format('opentype');
	font-display: auto;
	font-style: normal;
	font-weight: 400;
	font-stretch: normal;
}

@font-face {
	font-family: 'Bebas Bold';
	src: url('https://use.typekit.net/af/525920/00000000000000003b9b155b/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
			format('woff2'),
		url('https://use.typekit.net/af/525920/00000000000000003b9b155b/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
			format('woff'),
		url('https://use.typekit.net/af/525920/00000000000000003b9b155b/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
			format('opentype');
	font-display: auto;
	font-style: normal;
	font-weight: 700;
	font-stretch: normal;
}

html,
body {
	overflow-x: hidden;
	overflow-y: overlay;
	overscroll-behavior: none;
}

body {
	margin: 0;
	font-family: 'Gibson', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body.modal-open {
	overflow-y: hidden;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}
