@import '../../../../styles/colors.scss';

.navigation {
	&__list {
		.selected {
			text-decoration: underline;
		}

		color: $shotlander-blue;
		font-family: 'Gibson', sans-serif;

		&:first-child {
			font-weight: 700;
			size: 1rem;
			margin-bottom: 1.5rem;
		}

		& li {
			margin-left: 2.438rem;
			font-weight: 400;
			size: 1rem;
			margin-bottom: 1.625rem;
		}

		button:disabled {
			color: $grey-70;
		}
	}

	&__social-media-logo-container {
		position: absolute;
		bottom: 6.25rem;
		display: flex;
		column-gap: 50px;
	}

	&__external-link {
		display: block;
		font-weight: 700;
		margin-bottom: 1.625rem;
		color: $shotlander-blue;
	}
}
