$headerHeight: 2rem;
$topBottomPaddingForCells: 0.8rem 0;

.scrollable-table {
	width: 100%;
	border-radius: 0.5rem;
	position: relative;

	table {
		width: 100%;
		padding: 0 1.5rem;
		white-space: nowrap;

		thead {
			white-space: nowrap;
			background: #f9f9f9;
			border-bottom: 1px solid #ededed;
		}

		tr {
			display: table-row;
			vertical-align: middle;
			height: $headerHeight;

			th,
			td {
				padding: $topBottomPaddingForCells; /*cell padding*/
				word-wrap: break-word;
				overflow-wrap: break-word;
				white-space: pre-line;

				&:first-child {
					box-shadow: 3px 0px 5px -3px rgba(0, 0, 0, 0.1);
				}
			}
		}

		th {
			min-width: 3.8rem;
		}
	}
}

.horizontal-overflow {
	$leftColumnWidth: 100px;

	& > div {
		overflow-x: auto;
		margin-left: $leftColumnWidth;
	}

	th,
	td {
		font-size: 12px;
		font-weight: 400;
		font-family: 'Gibson';
		line-height: 16px;

		&:first-child {
			position: absolute;
			left: 0;
			top: auto;
			width: $leftColumnWidth;
		}
	}

	// since we are using margin to move the rest of the table, this is needed to also fill in the first table header background
	th:first-child {
		background: #f9f9f9;
		border-bottom: 1px solid #ededed;
	}

	td:not(:first-child) {
		font-size: 18px;
		font-family: 'Bebas Bold';
		padding-top: 25px;
	}
}
