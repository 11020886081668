@import '../../../../styles/colors.scss';

/* Customize the label (the checkbox-container) */
.checkbox-container {
	display: block;
	position: relative;
	padding-left: 1.75rem;
	cursor: pointer;
	font-size: 0.875rem;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

/* Create a custom checkbox */
.checkbox {
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	background-color: $white;
	border: 2px solid $shotlander-blue;
}

/* Create the checkbox/indicator (hidden when not checked) */
.checkbox:after {
	content: '';
	position: absolute;
	display: none;
}

/* Show the checkbox when checked */
.checkbox-container input:checked ~ .checkbox:after {
	display: block;
}

/* Style the checkbox/indicator */
.checkbox-container .checkbox:after {
	left: 5px;
	top: 2px;
	width: 5px;
	height: 10px;
	border: solid $shotlander-blue;
	border-width: 0 2px 2px 0;
	transform: rotate(45deg);
}
