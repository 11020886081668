@import '../../../../styles/utility.scss';
@import '../../../../styles/colors.scss';

.events-list {
	min-height: $app-height;
	margin: auto;
	max-width: 467px;
	padding: 0.25em 1em;

	&__title {
		font-weight: 700;
		font-size: 2em;
		font-family: 'Gibson';
		color: #003865;
		margin-top: 1em;
		margin-bottom: 1em;
		line-height: 2.5rem;
	}

	@media screen and (max-width: 650px) {
		&__title {
			font-size: 1.75em;
		}
	}

	&__events {
		&--search {
			width: 100%;
			padding: 35px 30px;
			background: $grey-ef;
			margin-bottom: 46px;
			display: flex;
			flex-direction: column;

			input {
				background-color: white;
			}

			button {
				margin-left: auto;
				padding: 5px;
			}
		}

		&--ongoing,
		&--upcoming,
		&--past {
			margin-bottom: 2em;

			.no-results {
				width: 100%;
				height: 6rem;
				background-color: $grey-ef;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: 1em;
			}
		}

		h3 {
			color: #737373;
			font-size: 1.5em;
		}

		&--header {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		@media screen and (max-width: 650px) {
			h3 {
				font-size: 1em;
			}
		}
	}

	&__card-wrapper {
		margin-top: 1em;
	}
}
