$filter-section-height: 53px;

.leaderboard {
	&__overlay-section {
		overflow-y: overlay;
		padding: 0 1rem;
		height: 100vh;
	}

	&__filters {
		display: flex;
		justify-content: space-between;
		column-gap: 10px;
		padding: 0.625rem 1rem;
		min-height: $filter-section-height;

		& > button {
			flex: 1 1 0px;
		}
	}

	&__container {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
	}
}
