.event-info__icons {
	display: inline-grid;
	grid-template-columns: auto auto auto;
	grid-template-rows: auto;
	grid-row-gap: 14px;
	grid-column-gap: 24px;
	margin-top: 24px;

	div {
		display: flex;

		.icon {
			overflow: visible;
		}

		span {
			font-size: 14px;
			line-height: 20px;
			padding-left: 0.688rem;
			margin-top: -3px;
		}
	}
}

@media screen and (max-width: 655px) {
	.event-info__icons {
		grid-template-columns: auto auto;
		margin-top: 14px;

		div {
			span {
				font-size: 12px;
			}
		}
	}

	.small-screen-flex-col {
		display: flex;
		flex-direction: column;
	}
}
