.carousel-dot {
	display: inline-block;
	height: 8px;
	width: 8px;
	border-radius: 50%;
	margin: 15px 7px 0px;
	background-color: #bdbcbc;

	&:hover {
		cursor: pointer;
	}
	&.active {
		background-color: #003865;
	}
}

.carousel-wrapper {
	overflow: hidden;
	width: 100%;
	height: 100%;

	&__content {
		display: flex;
		transition: all 400ms linear;

		& > * {
			width: 100%;
			flex-grow: 1;
			flex-shrink: 0;

			@media screen and (min-width: 650px) {
				width: 33.33%;
				max-width: 33.33%;
			}
		}
	}
}
