$header-height: 72px;
$app-height: calc(100vh - $header-height);

.no-scroll {
	overflow-x: hidden;
	overflow-y: hidden;
}

*::-webkit-scrollbar-thumb {
	background-color: lightgrey;
	border: 5px solid transparent;
	border-radius: 8px;
	background-clip: padding-box;
}

*::-webkit-scrollbar {
	width: 14px;
	height: 14px;
	background-color: transparent;
}

.disable-link {
	pointer-events: none;
	cursor: default;
}

.inverted {
	transform: rotate(180deg);
}
