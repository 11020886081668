@import '../../../../styles/colors.scss';

.radio-container {
	display: block;
	position: relative;
	padding-left: 1.75rem;
	cursor: pointer;
	font-size: 0.875rem;

	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}
}

.radio-button {
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	border-radius: 50%;
	background-color: $white;
	border: 2px solid $shotlander-blue;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radio-button:after {
	content: '';
	position: absolute;
	display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .radio-button:after {
	display: block;
}

/* Style the indicator (dot/circle) */
.radio-container .radio-button:after {
	top: 4px;
	left: 4px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: $shotlander-blue;
}
