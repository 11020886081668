@import '../../../../styles/colors.scss';
@import '../../../../styles/utility.scss';

.event-banner {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	color: $white;
	padding: 20px 40px;

	background-image: linear-gradient(
			180deg,
			#2b2b2b 0%,
			rgba(117, 117, 117, 0.4) 93.78%
		),
		url('../../../../assets/EventWelcomeBackground.png');
	background-size: cover;
	background-repeat: no-repeat;
	object-fit: fill;
	min-height: 200px;

	h3 {
		font-weight: 700;
		font-size: 22px;
		line-height: 23px;
	}

	@media screen and (min-width: 655px) {
		padding: 40px;
		background-image: linear-gradient(
				180deg,
				#2b2b2b 0%,
				rgba(117, 117, 117, 0.4) 93.78%
			),
			url('../../../../assets/EventWelcomeDesktop.png');

		h3 {
			font-weight: 700;
			font-size: 32px;
			line-height: 40px;
		}
	}
}
