@import '../../../../styles/colors.scss';

.warning-section {
	position: relative;
	font-size: 14px;
	width: 100%;
	padding-top: 88px;
	background: white;

	div {
		margin: 0 auto;
		width: 250px;
	}

	.warning-icon {
		position: absolute;
		width: 40px;
	}

	.error-text {
		color: $red;
	}

	@media screen and (min-width: 655px) {
		div {
			width: 425px;
			font-size: 16px;
		}

		.warning-icon {
			width: 50px;
			left: 35%;
		}
	}
}
