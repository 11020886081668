@import '../../../../styles/colors.scss';
@import '../../../../styles/utility.scss';

.app-header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background-color: white;
	display: flex;
	height: $header-height;
	align-items: center;
	z-index: 10;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);

	&__logo-container {
		background-color: $shotlander-blue;
	}

	&__logo {
		max-width: 120px;

		@media screen and (max-width: 655px) {
			max-width: 80px;
		}
	}

	&__divider {
		margin-right: 0.563rem;
		margin-left: 0.563rem;
	}

	&__title {
		max-width: 1184px;
		font-size: 20px;
		font-family: 'Bebas Neue';
		letter-spacing: 1.3px;

		@media screen and (max-width: 655px) {
			font-size: 14px;
			max-width: 238px;
		}
	}

	&__nav-container {
		transition: transform 500ms ease;
		padding: 1.5rem 1.5rem 0 1.5rem;
		display: grid;
		position: fixed;
		top: $header-height;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: $white;
		height: 100%;
		z-index: 11;
	}

	&__nav-close {
		transform: translateX(100%);
	}
}
