@import '../../../../styles/colors.scss';

.dark-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.8);
	visibility: visible;
}

.modal {
	@media screen and (min-width: 650px) {
		width: 450px;
		height: 515px;
	}

	width: 90%;
	height: 50%;
	max-height: 90%;
	z-index: 10;
	border-radius: 8px;
	background-color: white;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	padding: 1px;

	h2 {
		color: $shotlander-blue;
		font-family: 'Gibson';
		font-style: normal;
		font-weight: 700;
		font-size: 1.125rem;
		line-height: 1.25rem;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.delete-icon {
		position: absolute;
		right: 15px;
		top: 15px;
		transform: scale(1.25);
	}
}

.bottom-drawer-apply__section {
	padding-top: 0.5rem;
	display: flex;
	flex-direction: row;
	column-gap: 1rem;
	justify-content: space-evenly;
	align-items: center;
	border-top: 1px solid rgba(0, 0, 0, 0.1);

	button {
		width: 50%;
		height: 3.125rem;
		border: 1px solid #003865;
		font-family: 'Bebas Neue';
		font-size: 18px;
		letter-spacing: 1.3px;
		color: #003865;
	}
}
