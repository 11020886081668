@import '../../../../../styles/colors.scss';

.bottom-drawer-action__button {
	height: 32px;
	display: flex;
	align-items: center;
	border: 1px solid #dcdcdc;
	border-radius: 16px;
	padding: 0 10px;

	.filter-count__div {
		font-family: 'Gibson';
		font-style: normal;
		font-weight: 700;
		font-size: 12px;
		line-height: 24px;
		color: white;

		width: 18px;
		height: 18px;
		background: #003865;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 0.5rem;
	}
}

.bottom-drawer-filter-count__span {
	background: rgba(0, 56, 101, 0.05) !important;
	border: 1px solid rgba(0, 56, 101, 0.3);

	span {
		color: #003865;
		font-weight: 700;
	}
}

.bottom-drawer-action__span {
	font-size: 14px;
	line-height: 24px;
}

.bottom-drawer-action__arrow {
	margin-left: auto;
}

.bottom-drawer-filters__section {
	height: calc(100% - 4.5rem);
	overflow-y: auto;

	& button {
		width: 100%;
		height: 3.25rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-right: 0.33rem;
	}

	@keyframes hide-scroll {
		from,
		to {
			overflow: hidden;
		}
	}
}

.icon-background {
	background-color: $grey-ef;
	border-radius: 6px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 1.68rem;
	height: 1.68rem;
}
