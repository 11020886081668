.marketing-leaderboard__pre-event-details {
	color: white;
	font-family: 'Gibson';
	position: relative;

	&__card {
		border-bottom: 0.0625em solid rgba(255, 255, 255, 0.3);
		padding: 1em 0;
		display: flex;
		flex-direction: column;

		&:last-child {
			border-bottom: none;
		}

		&__title {
			font-size: 2.875em;
		}

		&__descriptions {
			font-size: 1.5em;
			ul {
				list-style-type: disc;

				li {
					margin-left: 2em;
				}
			}

			.no-bullet {
				list-style-type: none;
			}
		}
	}
}
