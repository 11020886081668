$header-height: 64px;
$footer-height: 82px;

.header {
	display: flex;
	justify-content: center;
	width: 100%;
	height: $header-height;
}

.footer {
	padding: 16px 36px;
	width: 100%;
	height: $footer-height;

	button {
		width: 100%;
		height: 3.125rem;
		border: 1px solid #003865;
		font-family: 'Bebas Neue';
		font-size: 18px;
		letter-spacing: 1.3px;
		color: #003865;
		padding-top: 4px;
	}
}

.content {
	display: flex;
	flex-direction: column;
	row-gap: 1.25rem;
	padding: 1.25rem;
	background-color: #f9f9f9;
	width: 100%;
	height: calc(100% - $header-height - $footer-height);
	overflow-y: overlay;

	&__quick-info {
		text-align: center;
		font-style: normal;
		font-weight: 400;

		h1 {
			font-family: 'Bebas Neue';
			font-size: 40px;
			line-height: 48px;
			color: #003865;
		}
	}

	&__pie-chart {
		width: 100%;
		display: flex;
		justify-content: center;

		.legend-name {
			padding-bottom: 5px;
		}
		.legend-name::first-letter {
			text-transform: capitalize;
		}
	}

	&__trending-region {
		display: flex;
		width: 100%;

		h1 {
			font-family: 'Gibson';
			font-size: 30px;
			line-height: 30px;
			font-weight: 600;
			color: #003865;
			margin-bottom: 10px;
		}

		.square-regions {
			margin-left: auto;
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-row: auto auto;
			grid-column-gap: 5px;
			grid-row-gap: 5px;
		}
	}

	h4,
	span {
		font-family: 'Gibson';
		line-height: 12px;
		color: #737373;
	}

	h4.sub-title {
		font-size: 14px;
	}

	span {
		font-size: 12px;
	}

	g:focus,
	path:focus {
		outline: none !important;
	}
}
