.data-card {
	width: 100%;
	border-radius: 8px;
	background-color: #ffffff;
	padding: 24px 16px;
	border: 1px solid #ededed;
	box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.07);

	&__header {
		display: flex;
		align-items: center;

		h2.title {
			font-size: 1rem;
			font-weight: 400;
			line-height: 1rem;
			color: #373737;
		}

		button.info-section {
			border-radius: 5px;
			background-color: #f9f9f9;
			border: none;
			font-size: 0.75rem;
			color: #737373;
			line-height: 14px;
			font-family: 'Gibson';
			padding: 0.5rem;
			margin-left: auto;
		}
	}

	&__content {
		display: flex;
		justify-content: center;
		align-items: center;
		padding-top: 2rem;
	}
}
