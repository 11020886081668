@import '../../../../styles/colors.scss';

.app-footer {
	background-color: $grey-33;
	width: 100%;
	padding-top: 16px;
	padding-bottom: 16px;
	color: white;
	display: flex;
	align-items: center;
	flex-direction: column;

	&__title {
		text-transform: 'capitalize';
		font-size: 16px;
		font-weight: 700;
		padding-bottom: 12px;
	}

	div:not(:first-child) {
		padding-bottom: 12px;
		font-size: 14px;

		&.social-media-container {
			padding-top: 8px;
			display: flex;
			column-gap: 50px;
		}
	}
}
