@import '../../../../styles/colors.scss';

.error-banner {
	border: 1px solid #f4f4f4;
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.07);
	border-radius: 8px;
	padding: 0.75rem 1rem 0.75rem 1rem;
	display: flex;
	justify-content: space-between;
	background-color: #fff;
	font-size: 1rem;
	color: #000;
}

.error-banner__refresh-button {
	color: $shotlander-blue;
	border: 0;
	font-weight: 700;
	display: flex;
	align-items: center;
	font-size: 0.875rem;

	svg {
		margin-right: 0.25rem;
	}
}
