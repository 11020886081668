@for $i from 1 through 5 {
	.marketing-leaderboard__leader-card-wrapper:nth-child(#{$i}) {
		animation-delay: $i * 0.5s;
	}
}

@keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.marketing-leaderboard__leader-card-wrapper {
	opacity: 0;
	animation: fadein 2s forwards;

	&:last-child > div:last-child {
		visibility: hidden;
	}
}

.marketing-leaderboard__leader-card {
	display: flex;
	align-items: center;
	color: white;
	position: relative;
	font-family: 'Gibson';
	padding: 0.5em 0 0 0;
	height: 11.4em;
	overflow: hidden;

	&:last-child {
		border-bottom: none;
	}

	.leader-card__player-info {
		display: flex;
		flex-direction: column;
		gap: 0.9em;
		width: 80%;

		&__title {
			display: flex;
			align-items: center;
			gap: 2.2em;
			& > :first-child {
				font-size: 1.25em;
				font-weight: 600;
				color: rgba(255, 255, 255, 0.7);
			}

			& > :not(:first-child) {
				font-size: 2.875em;
				line-height: 1.2em;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}

		&__location {
			margin-left: 2.2em;
			display: flex;
			gap: 0.75em;
			align-items: center;
			font-size: 1.3125em;
			font-weight: 500;

			& > div {
				padding: 0 0.5em;
				border-radius: 0.33em;
				border: 0.0625em solid white;
				white-space: nowrap;
			}

			& > span {
				line-height: 1em;
			}
		}
	}

	.leader-card__player-rank {
		margin-left: auto;
		height: 5.47em;
		min-width: 5.47em;
		position: relative;

		& > span {
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 2.875em;
			line-height: 2.15em;
			font-weight: 700;
			font-family: 'Bebas Neue';
		}
	}
}
