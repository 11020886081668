.marketing-section {
	&__event-banner {
		$colorStart: rgba(0, 0, 0, 0);
		$colorEnd: rgba(0, 0, 0, 0.8);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	}

	.custom-counter {
		list-style: none;
		padding-left: 40px;

		li {
			margin: 0 0 0.5rem 0;
			counter-increment: step-counter;
			position: relative;
			padding-left: 10px;
		}
		li::before {
			content: counter(step-counter);
			color: white;
			font-size: 16px;
			position: absolute;
			--size: 32px;
			left: calc(-1 * var(--size) - 10px);
			line-height: var(--size);
			width: var(--size);
			height: var(--size);
			top: 4px;
			background-color: #003865;
			border-radius: 50%;
			text-align: center;
		}
	}

	&__event-info {
		grid-template-columns: auto auto auto;

		div {
			span {
				font-size: 14px;
				line-height: 20px;
			}
		}
	}
}
