.info-icon {
	border-radius: 50%;
	background: none;
	border: 1px solid #707070;
	height: 1rem;
	width: 1rem;
	color: #707070;
	position: relative;

	.popup {
		background-color: #003865;
		color: white;
		border-radius: 0.5rem;
		padding: 0.5rem 1rem;
		text-align: left;
		font-size: 14px;
		line-height: 18px;
		width: 9rem;
		position: absolute;
	}

	.arrow {
		border: solid 10px transparent;
		border-right-color: #003865;
		position: absolute;
	}
}

.info-icon__normal {
	&__popup {
		right: 1.5rem;

		@media screen and (min-width: 650px) {
			left: 1.5rem;
		}
	}

	&__arrow {
		right: 0.6rem;
		transform: rotate(180deg);

		@media screen and (min-width: 650px) {
			left: 0.6rem;
			transform: rotate(0);
		}
	}
}

.info-icon__small {
	height: 0.75rem;
	width: 0.75rem;
	border-width: 0.8px;

	& > span {
		font-size: 10px;
	}

	&__popup {
		right: -0.6rem;
		bottom: 1.3rem;
	}

	&__arrow {
		right: -0.3rem;
		bottom: 0.4rem;
		transform: rotate(270deg);
	}
}
