@import '../../../../styles/colors.scss';

$gapBetweenComponents: 3em;
$firstColumn: 30%;
$secondColumn: 70%;
$backgroundColor: rgba(0, 56, 101, 1);

.marketing-leaderboard-landing-page {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 2em 0 0em 2em;
	z-index: 10;
	display: flex;
	flex-direction: column;
	background: $backgroundColor;

	.fade-in {
		opacity: 1;
		transition: opacity 3s;
	}
	.fade-out {
		opacity: 0;
		content: '';
		transition: opacity 1s;
	}

	.leaderboard-header {
		display: flex;
		margin-bottom: $gapBetweenComponents;
		width: 100%;
		color: white;
		height: 4.75em;
		letter-spacing: 0.2em;
		font-family: 'Gibson';
		font-weight: 600;
		z-index: 20;

		&__current {
			background-color: rgba(1, 42, 77, 1);
			color: white;
			width: $firstColumn;
			font-size: 1.75em;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&__upcoming {
			display: flex;
			align-items: center;
			width: $secondColumn;
			background: linear-gradient(
				270deg,
				#002a4c 87.88%,
				rgba(0, 42, 76, 0) 99.53%
			);
			opacity: 70%;

			& > div {
				display: flex;
				margin-left: 5em;
				overflow-x: hidden;
				white-space: nowrap;

				div {
					font-size: 1.25em;
					border-right: 0.0625em solid #bbca18;
					padding: 0 2.5em;

					&:first-child {
						padding: 0 2.5em 0 0;
					}

					&:last-child {
						border-right: none;
					}
				}
			}
		}
	}

	.leaderboard-content {
		display: flex;
		height: calc(100% - 6.75em);
		overflow: hidden;
		flex-grow: 1;

		&__cards {
			width: $firstColumn;
			overflow-y: hidden;
			margin-top: -$gapBetweenComponents;
			overscroll-behavior: none;

			&__no-players {
				border: 0.065em solid white;
				height: 5.66em;
				width: 100%;
				margin-top: calc($gapBetweenComponents - 0.9em);
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 1.4375em;
				font-weight: 500;
				color: white;
			}
		}

		&__social {
			width: $secondColumn;
			display: flex;
			flex-direction: column;
			color: white;

			& > div {
				height: 100%;
				margin-left: $gapBetweenComponents;
			}

			&__video {
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}
}

.marketing-leaderboard__divider {
	width: 100%;
	height: 0.75em;
	border-bottom: 0.0625em solid rgba(255, 255, 255, 0.3);
	text-align: center;

	& > span {
		font-size: 1em;
		letter-spacing: 0.2em;
		background-color: rgba(0, 56, 101, 1);
		padding: 0 0.625em;
		color: white;
	}
}
