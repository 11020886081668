$shotlander-blue: #003865;
$secondary-blue: #024e8a;
$black: #000;
$white: #fff;
$red: #d92733;
$err-background-red: #fcecec;
$yellow-green: #ccdb2a;
$green: #00ab53;
$success-background-green: #ecf6ec;
$blue-100: #e5eef1;
$blue-300: #a6c5d0;
$blue-500: #005978;
$blue-900: #002633;
$grey-33: #333333;
$grey-70: #707070;
$grey-73: #737373;
$grey-ef: #efefef;
$grey-f4: #f4f4f4;
$grey-f9: #f9f9f9;
$grey-f2: #f2f2f2;
$white: #ffffff;
$input-border-blue: #aac6c9;
$input-color: #005978;
