.event-card {
	border: 1px solid #ededed;
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.07);
	border-radius: 8px;
	max-width: 467px;

	&__hero-image {
		overflow: hidden;

		img {
			object-fit: cover;
			width: 100%;
			border-top-right-radius: 8px;
			border-top-left-radius: 8px;
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		margin-bottom: 1.5rem;
		margin-right: 1.5rem;
		margin-left: 1.5rem;
		margin-top: 1rem;
	}

	&__title {
		color: #003865;
		font-family: 'Gibson';
		font-style: normal;
		font-weight: 700;
		font-size: 18px;
		line-height: 18px;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;

		@media screen and (min-width: 650px) {
			font-size: 28px;
			line-height: 28px;
		}
	}
	&__description {
		font-family: 'Gibson';
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 17px;

		@media screen and (min-width: 650px) {
			padding-top: 5px;
			font-size: 16px;
			line-height: 22px;
		}
	}

	&__icon-container {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: auto;
		grid-row-gap: 20px;
		margin-top: 20px;

		div {
			display: flex;

			.icon {
				overflow: visible;
			}

			span {
				margin-top: -3px;
				font-size: 12px;
				line-height: 20px;
				padding-left: 0.688rem;

				@media screen and (min-width: 650px) {
					margin-top: -5px;
					font-size: 16px;
					line-height: 24px;
				}
			}
		}
	}
}
