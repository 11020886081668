@import '../../../../styles/utility.scss';
@import '../../../../styles/colors.scss';

.map-container {
	overflow: hidden;
	.map {
		height: $app-height;
		// need to hide bing map logos at bottom of screen
		.bm_bottomRightOverlay {
			visibility: hidden;
		}
		.bm_LogoContainer {
			visibility: hidden;
		}
	}
}
.map-error {
	position: absolute;
	width: 100%;
	height: $app-height;
	z-index: 11;
	background-color: $white;
}
