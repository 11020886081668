.not-found {
	display: flex;
	flex-direction: column;
	.warning-section {
		align-self: center;

		p,
		ul {
			margin-bottom: 0.75rem;
		}
	}
	h3 {
		align-self: center;
	}

	ul {
		list-style-type: disc;

		li {
			margin-left: 2rem;
		}
	}
}
