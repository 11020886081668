@import '../../../../../styles/colors.scss';
@import '../../../../../styles/variables.module.scss';

.leaderCard__distance-number {
	color: $shotlander-blue;
	font-weight: 400;
	min-width: 3.125rem;
	height: 3.125rem;
	font-family: 'Bebas Neue';
	background-color: $grey-f9;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.25rem;
	border-radius: 0.5rem;
	padding: 0.9rem 0.6rem 0.6rem 0.6rem;

	svg {
		padding-right: 12px;
		padding-bottom: 10px;
		overflow: visible;
	}
}

.leadercard__rank-number {
	height: 2rem;
	width: 2rem;
	font-size: 0.875rem;
	line-height: 0.875rem;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: 'Gibson';
}

.leader-card__inner {
	padding: 0.5rem;
	height: #{$leader-card-height}px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
