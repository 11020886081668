@import '../../../../styles/colors.scss';

.slide-up-animation {
	transition: all 1s ease;
}

.bottom-drawer {
	width: 100%;
	position: fixed;
	left: 0;
	bottom: 0;
	z-index: 10;

	&__grab-bar {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
		gap: 0.5rem;
		border-radius: 10px 10px 0 0;
		padding: 0.5rem 1rem;
		touch-action: none;

		&__content {
			width: 1.875rem;
			height: 3px;
			background-color: $grey-70;
			border-radius: 2px;
		}

		&__event-name {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
		}
	}

	.select {
		border: 1px solid #dadada;
		border-radius: 16px;
		padding: 4px 8px;
		color: #003865;
		font-weight: bold;
		font-size: 14px;
		margin-left: 10px;

		select {
			// Get rid of default chevron
			-moz-appearance: none; /* Firefox */
			-webkit-appearance: none; /* Safari and Chrome */
			appearance: none;
			outline: none;
			background: transparent;
			cursor: pointer;
		}
	}
}
