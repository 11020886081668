@import '../../../../styles/colors.scss';
@import '../../../../styles/utility.scss';

.event-welcome__content {
	min-height: $app-height;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 2.5em;

	font-family: 'Gibson';
	font-style: normal;
	font-weight: 400;
	background: $grey-f9;

	.event-info {
		h5 {
			margin-top: 8px;
			font-size: 14px;
			line-height: 20px;
		}

		.error-header {
			align-self: center;
		}

		@media screen and (min-width: 655px) {
			.event-info__text {
				width: 467px;
			}
		}
	}
}
