@import '../../../../styles/colors.scss';

.challenge-card {
	width: 342px;
	background: $white;
	border-radius: 8px;
	padding: 30px;
	margin-top: 24px;
	display: flex;
	flex-direction: column;
	row-gap: 10px;

	font-family: 'Gibson';
	font-style: normal;
	font-weight: 400;
	color: $black;

	&__title {
		font-weight: 700;
		font-size: 18px;
		line-height: 18px;
	}

	&__hole-info {
		font-size: 12px;
		line-height: 12px;
	}

	&__body {
		font-size: 12px;
		line-height: 17px;

		button {
			text-decoration: underline;
		}
	}

	&__round-info {
		display: flex;
		flex-direction: row;
		gap: 8px;
		flex-wrap: wrap;
	}

	&__round-detail {
		display: flex;
		justify-content: center;
		background: $grey-f9;
		border-radius: 4px;
		height: 32px;
		color: $grey-73;

		span {
			padding: 10px 15px 11px 15px;
			font-size: 12px;
			line-height: 12px;
			text-align: center;
		}
	}

	&__action-buttons {
		display: flex;
		flex-direction: row;
		column-gap: 20px;
		margin-top: 22px;

		button {
			font-size: 14px;
			line-height: 24px;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 50px;
			padding-top: 3px;
		}

		button.results {
			border: 1px solid $white;
			font-family: 'Bebas Neue';
			font-size: 18px;
			line-height: 22px;
			font-style: normal;
			letter-spacing: 1.3px;
			width: 167px;
			background: $shotlander-blue;
			color: $white;

			&:hover {
				background-color: $secondary-blue;
			}
		}

		button.highlights {
			flex: 1;
		}
	}

	&__rules-modal {
		height: 100%;

		p {
			border-radius: 5px;
			padding: 0 16px;
			color: $black;
		}
	}
}

@media screen and (min-width: 655px) {
	.challenge-card {
		width: 467px;
		margin-top: 40px;

		h4 {
			font-size: 28px;
			line-height: 28px;
		}

		&__hole-info {
			font-size: 16px;
			line-height: 24px;
		}
		&__body {
			font-size: 16px;
			line-height: 22px;
		}

		&__round-info {
			gap: 16px;
		}

		&__round-detail {
			span {
				height: 32px;
				font-size: 16px;
				line-height: 16px;
			}
		}

		&__action-buttons {
			button.results {
				font-size: 18px;
				line-height: 22px;
				width: 300px;
			}

			button.highlights {
				font-size: 18px;
				line-height: 31px;
			}
		}
	}
}
